.site .capabilities, .site .blog-content, .home-clients, .site .technology, .site .mission, .site .flutter-lead-in, .clients, .work-with-us {
  padding-left: 20px;
  padding-right: 20px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .capabilities, .site .blog-content, .home-clients, .site .technology, .site .mission, .site .flutter-lead-in, .clients, .work-with-us {
      padding-left: 0;
      padding-right: 0; } }

.site .capabilities, .site .blog-content, .home-clients, .site .technology, .site .mission, .site .flutter-lead-in, .clients, .work-with-us {
  padding-left: 20px;
  padding-right: 20px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .capabilities, .site .blog-content, .home-clients, .site .technology, .site .mission, .site .flutter-lead-in, .clients, .work-with-us {
      padding-left: 0;
      padding-right: 0; } }

.site .intro {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  height: 360px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .intro {
      height: 100%; } }

.site .intro .card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse; }

.site .intro .card .card-content {
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin: 0 auto;
  width: 320px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .intro .card .card-content {
      width: 1024px; } }
  .site .intro .card .card-content .logo {
    width: 90px;
    opacity: 0.92; }
    @media screen and (min-width: 992px) and (orientation: landscape) {
      .site .intro .card .card-content .logo {
        width: 120px; } }
  .site .intro .card .card-content .look {
    text-decoration: underline; }
  .site .intro .card .card-content .tagline-holder #tagline-image {
    width: 300px; }
    @media screen and (min-width: 992px) and (orientation: landscape) {
      .site .intro .card .card-content .tagline-holder #tagline-image {
        width: auto;
        height: 40vh; } }
  .site .intro .card .card-content .divider {
    width: 80px;
    height: 1px;
    margin-top: 15px;
    background-color: rgba(0, 0, 0, 0.2); }
  .site .intro .card .card-content .card-bottom-part {
    margin-top: 15px;
    max-width: 100%;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) and (orientation: landscape) {
      .site .intro .card .card-content .card-bottom-part {
        max-width: 70%;
        margin-bottom: 100px; } }
    .site .intro .card .card-content .card-bottom-part .blurb .line {
      font-family: "Playfair Display", serif;
      font-size: 0.7em;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      color: rgba(44, 44, 44, 0.78); }
      @media screen and (min-width: 992px) and (orientation: landscape) {
        .site .intro .card .card-content .card-bottom-part .blurb .line {
          font-size: 1.2em;
          line-height: 1.3em;
          padding: 4px 0 6px; } }
    .site .intro .card .card-content .card-bottom-part .reload-button {
      display: none;
      height: 32px;
      margin-top: 20px;
      margin-left: -10px; }
      @media screen and (min-width: 992px) and (orientation: landscape) {
        .site .intro .card .card-content .card-bottom-part .reload-button {
          display: inline-block; } }
      .site .intro .card .card-content .card-bottom-part .reload-button .reload-button-content {
        float: left;
        display: flex;
        height: 100%;
        padding: 0 10px 0 10px;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        border: 1px solid rgba(255, 255, 255, 0);
        border-radius: 2px;
        cursor: pointer;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none; }
        .site .intro .card .card-content .card-bottom-part .reload-button .reload-button-content:hover {
          border: 1px solid rgba(255, 255, 255, 0.5); }
        .site .intro .card .card-content .card-bottom-part .reload-button .reload-button-content .reload-image {
          margin-right: 10px;
          width: 24px;
          height: 24px; }
        .site .intro .card .card-content .card-bottom-part .reload-button .reload-button-content .reload-button-text {
          -moz-user-select: none;
          -webkit-user-select: none;
          user-select: none;
          flex: 0 1 auto;
          color: #fff;
          text-transform: uppercase;
          font-size: 0.65em;
          font-family: "IBM Plex Sans", sans-serif; }
      .site .intro .card .card-content .card-bottom-part .reload-button .reload-button-note {
        cursor: pointer;
        font-weight: 400;
        height: 28px;
        line-height: 28px;
        margin-left: 10px;
        border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
        float: left;
        font-size: 0.65em;
        font-family: "IBM Plex Sans", sans-serif;
        color: #4e7a6b;
        padding-left: 5px;
        padding-right: 5px; }
        .site .intro .card .card-content .card-bottom-part .reload-button .reload-button-note:hover {
          color: #304c3e;
          background: rgba(255, 255, 255, 0.2); }

#scroll-notice {
  transition: opacity .25s ease-in-out; }

.site .capabilities, .site .blog-content, .home-clients, .site .technology, .site .mission, .site .flutter-lead-in, .clients, .work-with-us {
  padding-left: 20px;
  padding-right: 20px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .capabilities, .site .blog-content, .home-clients, .site .technology, .site .mission, .site .flutter-lead-in, .clients, .work-with-us {
      padding-left: 0;
      padding-right: 0; } }

.site .capabilities {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: start;
  margin-bottom: 30px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .capabilities {
      margin-bottom: 80px; } }

.site .capabilities .capabilities-card {
  width: 100%;
  margin-bottom: 30px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .capabilities .capabilities-card {
      min-height: 140px;
      margin-bottom: 0px; } }
  .site .capabilities .capabilities-card .title {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 0.85em;
    text-transform: uppercase;
    letter-spacing: -0.03em;
    text-align: left;
    line-height: 1.0em;
    margin-bottom: 20px;
    color: #05c977; }
    @media screen and (min-width: 992px) and (orientation: landscape) {
      .site .capabilities .capabilities-card .title {
        height: 70px;
        font-size: 1.15em; } }
    .site .capabilities .capabilities-card .title .title-text {
      transform: scale(1, 1.2); }
  .site .capabilities .capabilities-card .blurb {
    color: #222;
    font-size: 0.85em;
    text-align: left; }
    @media screen and (min-width: 992px) and (orientation: landscape) {
      .site .capabilities .capabilities-card .blurb {
        font-size: 1.0em; } }

.site .capabilities, .site .blog-content, .home-clients, .site .technology, .site .mission, .site .flutter-lead-in, .clients, .work-with-us {
  padding-left: 20px;
  padding-right: 20px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .capabilities, .site .blog-content, .home-clients, .site .technology, .site .mission, .site .flutter-lead-in, .clients, .work-with-us {
      padding-left: 0;
      padding-right: 0; } }

.site .blog-content {
  background: #f4f4f4;
  padding-top: 0px;
  padding-bottom: 20px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .blog-content {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .blog-content .inner {
      max-width: 1024px;
      margin: 0 auto; } }
  .site .blog-content h1 {
    color: #444; }
  .site .blog-content .blog-remainder {
    color: #777;
    font-size: 0.8em;
    margin-top: 20px;
    margin-bottom: 40px; }
    @media screen and (min-width: 992px) and (orientation: landscape) {
      .site .blog-content .blog-remainder {
        padding-left: 20px;
        padding-right: 20px; } }

.site .blog-content .blog-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .blog-content .blog-items {
      margin-left: -20px; } }

.site .blog-content .blog-items .blog-card {
  padding: 10px 0px;
  margin-top: 20px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .blog-content .blog-items .blog-card {
      padding: 20px;
      width: calc(33% - 60px);
      height: 260px;
      margin-left: 20px; } }
  .site .blog-content .blog-items .blog-card .blog-tag {
    display: inline-block;
    min-height: 16px;
    text-transform: uppercase;
    color: #05c977;
    font-weight: bold;
    font-size: 0.55em;
    letter-spacing: 0.15em;
    border-bottom: 1px solid rgba(64, 237, 164, 0.4); }
    @media screen and (min-width: 992px) and (orientation: landscape) {
      .site .blog-content .blog-items .blog-card .blog-tag {
        font-size: 0.6em;
        min-height: 24px; } }
  .site .blog-content .blog-items .blog-card .title {
    margin-top: 10px;
    font-family: "Playfair Display", serif;
    color: #222;
    font-size: 1.0em;
    cursor: pointer;
    overflow: hidden; }
    @media screen and (min-width: 992px) and (orientation: landscape) {
      .site .blog-content .blog-items .blog-card .title {
        font-size: 1.35em;
        height: 74px;
        line-height: 1.2em; } }
    .site .blog-content .blog-items .blog-card .title a {
      background: none !important;
      border-bottom: none;
      font-weight: 600; }
      .site .blog-content .blog-items .blog-card .title a:hover {
        border-bottom: 1px dotted #e2ccff; }
  .site .blog-content .blog-items .blog-card .blurb {
    margin-top: 10px;
    color: #444;
    font-size: 0.72em;
    line-height: 1.4em;
    text-align: justify;
    margin-bottom: 10px; }
    @media screen and (min-width: 992px) and (orientation: landscape) {
      .site .blog-content .blog-items .blog-card .blurb {
        font-size: 0.82em;
        height: 120px;
        margin-bottom: 50px; } }

.site .blog-content .blog-items .blog-card.hello {
  padding: 10px 20px;
  background: #f8f8f8;
  border: 1px solid rgba(64, 237, 164, 0.4); }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .blog-content .blog-items .blog-card.hello {
      padding: 20px; } }

a.button, button.button {
  display: inline-flex;
  flex-shrink: 1;
  flex-grow: 0;
  width: auto;
  min-height: 48px;
  min-width: 160px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  text-transform: uppercase;
  font-family: 'IBM Plex Sans', sans-serif;
  background: #8521ff;
  border: 1px solid #8521ff;
  color: #fff;
  cursor: pointer; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    a.button, button.button {
      background: none;
      color: #9242f4; }
      a.button:hover, button.button:hover {
        background: #8521ff;
        border: 1px solid #8521ff;
        color: #fff; } }
  a.button .button-content, button.button .button-content {
    font-weight: 600;
    font-size: 0.85em;
    letter-spacing: 0.04em; }

button.button {
  font-size: 1.0em; }

.site .capabilities, .site .blog-content, .home-clients, .site .technology, .site .mission, .site .flutter-lead-in, .clients, .work-with-us {
  padding-left: 20px;
  padding-right: 20px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .capabilities, .site .blog-content, .home-clients, .site .technology, .site .mission, .site .flutter-lead-in, .clients, .work-with-us {
      padding-left: 0;
      padding-right: 0; } }

.home-clients {
  margin-bottom: 30px;
  font-size: 0.8em; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .home-clients {
      padding-left: 0;
      padding-right: 0;
      padding-top: 10px;
      margin-bottom: 50px;
      font-size: 1.0em; } }

.home-clients .client-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .home-clients .client-list {
      margin-top: 0; } }
  .home-clients .client-list .client {
    font-size: 1.5em;
    color: #777;
    font-weight: 500; }
    @media screen and (min-width: 992px) and (orientation: landscape) {
      .home-clients .client-list .client {
        font-size: 2.2em;
        line-height: 0.7em;
        margin-top: 20px; } }
    .home-clients .client-list .client:nth-of-type(even) {
      color: #999;
      font-weight: 400; }
    .home-clients .client-list .client:not(:last-child) {
      margin-right: 20px; }

.site .capabilities, .site .blog-content, .home-clients, .site .technology, .site .mission, .site .flutter-lead-in, .clients, .work-with-us {
  padding-left: 20px;
  padding-right: 20px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .capabilities, .site .blog-content, .home-clients, .site .technology, .site .mission, .site .flutter-lead-in, .clients, .work-with-us {
      padding-left: 0;
      padding-right: 0; } }

.site .technology {
  margin-top: 40px;
  font-size: 0.85em;
  background: #8400ff;
  color: rgba(255, 255, 255, 0.8);
  padding-top: 30px;
  padding-bottom: 20px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .technology {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 120px;
      font-size: 1.0em; } }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .technology .inner {
      padding-top: 60px;
      padding-bottom: 40px;
      max-width: 1024px;
      margin: 0 auto; } }
  .site .technology .tech-intro {
    display: inline-block;
    font-size: 0.7em;
    margin-bottom: 30px;
    letter-spacing: 0.05em;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
    .site .technology .tech-intro .tech-intro-em {
      font-weight: 600; }

.site .tech-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .tech-card {
      flex-direction: row; } }
  .site .tech-card .tech-card-title {
    font-size: 1.1em;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
    font-weight: bold;
    letter-spacing: -0.03em; }
    @media screen and (min-width: 992px) and (orientation: landscape) {
      .site .tech-card .tech-card-title {
        font-size: 2.0em;
        min-width: 180px;
        margin-right: 40px;
        margin-bottom: 0; } }
  .site .tech-card .tech-card-blurb {
    font-size: 0.9em;
    color: rgba(255, 255, 255, 0.8); }

.site .top-section {
  display: block; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .top-section {
      height: calc(100vh - 0px); } }

.site .mobile-top-section {
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .mobile-top-section {
      display: none; } }
  .site .mobile-top-section img {
    width: 90px; }

.site .mission {
  font-family: "Playfair Display", serif;
  text-align: left;
  font-size: 1.4em;
  margin-top: 40px;
  margin-bottom: 50px;
  line-height: 1.3em;
  color: #333;
  font-weight: 300; }
  .site .mission em {
    font-style: normal;
    border-bottom: 1px solid rgba(64, 237, 164, 0.5); }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .mission {
      margin-top: 80px;
      margin-bottom: 50px;
      font-size: 2.4em;
      text-align: left; } }

.site .flutter-lead-in {
  font-size: 0.9em;
  color: #555;
  line-height: 1.4em;
  text-align: left;
  margin-bottom: 50px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .site .flutter-lead-in {
      font-size: 1.0em;
      line-height: 1.4em;
      text-align: left;
      margin-bottom: 80px;
      font-weight: 400;
      letter-spacing: 0.01em; } }
  .site .flutter-lead-in em {
    clear: none;
    font-style: normal;
    border-bottom: 1px solid rgba(64, 237, 164, 0.5); }

.clients {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 35px; }
  .clients .logo {
    margin-bottom: 15px; }
    .clients .logo img {
      width: 60px;
      height: 60px; }
      @media screen and (min-width: 992px) and (orientation: landscape) {
        .clients .logo img {
          width: 100px;
          height: 100px; } }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .clients .logo + .logo {
      margin-left: 20px; } }

.work-with-us {
  margin-bottom: 80px; }
  @media screen and (min-width: 992px) and (orientation: landscape) {
    .work-with-us {
      margin-bottom: 0; } }
  .work-with-us .work-with-us-intro {
    font-size: 1.1em;
    font-weight: 600;
    color: #9242f4; }
  .work-with-us .email {
    font-size: 1.7em;
    color: #333;
    font-weight: 200 !important;
    margin-top: 10px; }
    @media screen and (min-width: 992px) and (orientation: landscape) {
      .work-with-us .email {
        font-size: 3.1em; } }
  .work-with-us .contact-form {
    font-size: 0.9em; }
  .work-with-us a {
    font-weight: 600; }
